import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';


import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <GoogleOAuthProvider clientId='900075928002-akth2s07835vt25oleef9d9qmrclvae4.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>
  </Router>
);