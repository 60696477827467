import { Container } from "react-bootstrap";


function Myfooter() {

    return (
        <footer id="footer">
            <Container>
                <div className="my-3 footer-text">
                    Contact: <a href="mailto:oregonmathcircle@gmail.com">oregonmathcircle@gmail.com</a>
                </div>
            </Container>
        </footer>
    )

}


export default Myfooter;   