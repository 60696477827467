const faq_sections = [
    {
        title: "General Information",
        items: [
            {
                title: "What is Oregon Math Circle?",
                body: "Oregon Math Circle (OMC) is an organization that helps middle and high school students explore mathematics beyond their school curriculum through problem-solving, competitions, group discussions, and advanced math classes."
            },
            {
                title: "Who can join the Math Circle?",
                body: "OMC is open to middle and high school students who have an interest in mathematics. Some events may require specific qualifications, but in general, any motivated student is welcome to participate."
            },
            {
                title: "When do the meetings take place?",
                body: "Meetings are usually held on weekends. Check the event page for a detailed schedule."
            },
            {
                title: "What happens during meetings?",
                body: "During competition practice meetings, students work on practice problems together and learn advanced techniques. During educational meetings, students are introduced to undergraduate mathematics topics that is not generall taught to middle school and high school kids, in a way that both increases their appreciation for mathematics and enhances their understanding of it."
            }
        ]
    },
    {
        title: "Advanced Classes", 
        items: [
            {
                title: "What are the advanced classes?",
                body: "We strongly believe that students should be exposed to a wide variety of mathematical topics beyond just competition math. Our advanced classes are designed to introduce students to undergraduate-level mathematics topics (such as Group Theory and Game Theory) in a way that both increases their appreciation for mathematics and enhances their understanding of it."
            }, 
            {
                title: "I can barely keep up with my current level of math. Should I still take the advanced classes?",
                body: "Our classes are not meant to replace a university-level course or textbook. There are much better resources if you want to fully understand one of the topics we teach. Our classes are designed to be accessible to students of all mathematical backgrounds. We aim to body the questions - Why is this important? How does this relate to other things I know? What are some cool things I can do with this? "
            }, 
            {
                title: "What can I do if I get stuck on any of the material?", 
                body: "We would be more than happy to help any students that are struggling with the material from our classes (or any other math-related questions in general). Moreover, we believe any student will find that their peers will also be very willing and capable of helping them out."
            }
        ]
    },
    {
        title: "Competitions",
        items: [
            {
                title: "What are the competition practices?",
                body: "Competition practices focus on preparing students for various math competitions, such as ARML, HMMT, and others. Students work on practice problems and learn advanced techniques."
            }, 
            {
                title: "I want to continue practicing math outside of meetings. What can I do?",
                body: "We have many, many resources listed in our Discord server. If you would like more personalized recommendations, feel free to ask any of the OMC leaders."
            }
        ]
    },
    {
        title: "Team Selection",
        items: [
            {
                title: "How are teams selected for competitions?",
                body: "Teams are selected based on TSTs (Team Selection Tests) and some other criteria (such as meeting attendance and POTD performance)."
            },
            {
                title: "What is the TST (Team Selection Test)?",
                body: "The TST is a test with original problems written by OMC leaders that is used to select students for competition teams."
            },
            {
                title: "How many students does OMC send to ARML and HMMT? Will students be cut?",
                body: "We expect to send roughly 3 teams of 15 people each to ARML. We will not cut students from the ARML team. ARML has a system where incomplete teams can still participate (individual rounds take place as usual, and the team is joined with other incomplete teams for team rounds). On the other hand, we are only able to send 1 team of 8 people to HMMT. This means we do cut people, and team selection is highly competitive."
            }
        ]
    },
    {
        title: "Logistics",
        items: [
            {
                title: "What is cost of the ARML trip?",
                body: "The trip should cost approximately $500-$700, depending on several factors which change year to year. We will do our best to keep the cost as low as possible."
            },
            {
                title: "Where will students stay during the ARML trip?",
                body: "Housing will be provided in the dorms at the University of Reno."
            },
            {
                title: "My child is very young. Can they still attend the ARML trip? How will housing work?", 
                body: "We have had students as young as second grade attend the ARML trip in the past. For more mature students, they are paired with a roommate and stay in the dorms, supervised throughout the trip by a collection of chaperones. For younger students, we generally ask that at least one parent accompany them on the trip (who they will also room with)."
            },
            {
                title: "What should students bring on the ARML trip?",
                body: "Students should bring a few pairs of clothes (depending on how long they're staying), a water bottle, a light backpack, toiletries, a notebook, some writing utensils, and electronic devices for communication. Students are encouraged to bring money (roughly $100) in case they decide to eat at a restaurant or purchase items from the ARML store. Students should not bring unnecessary valuables or large suitcases."
            }
        ]
    },
];

export default faq_sections;