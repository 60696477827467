import leaders_sections from "./leaders"
import { Row, Col, Image } from "react-bootstrap";


function LeaderSection() {
    return <Row xs={1} md={3} key="home-leader-section">
        {leaders_sections.map((person, i) => (<>
            <Col key={`leader-${i}`} className="leader-info">
                <div className="leader-image-wrapper">
                    <Image src={`${person.image}`} alt={person.name} className="leader-image" />
                </div>
                <h4> {person.name} </h4>
                <h5> {person.role} </h5>
                <p> {person.shortdesc} </p>
            </Col>
        </>))}
    </Row>
}

export default LeaderSection; 