import anay_prof_pic from "../images/anay-prof-pic.jpg";
import norse_prof_pic from "../images/norse-prof-pic.jpg";
import shreyan_prof_pic from "../images/shreyan-prof-pic.jpg";

var leaders_sections = [
    {
        "name": "Shreyan Paliwal",
        "role": "Leader",
        "shortdesc": "Shreyan Paliwal is a senior at Flex Online High School. He has qualified for the USAJMO and attended the Canada/USA Mathcamp.",
        "image": shreyan_prof_pic
    },
    {
        "name": "Anay Aggarwal",
        "role": "Co-leader",
        "shortdesc": "Anay Aggarwal is a junior at Westview High School. He has qualified for the USAMO and the MIT PRIMES-USA program.",
        "image": anay_prof_pic
    },
    {
        "name": "Manuel Norse",
        "role": "Coach",
        "shortdesc": "Manuel Norse has been coaching math competitions for two decades, leading the Oregon ARML team to multiple top 20 finishes.",
        "image": norse_prof_pic
    }
]

export default leaders_sections;