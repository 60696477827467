import React from 'react';
import { useState, useEffect } from 'react';

import { Image, Container, Stack, Row, Col } from 'react-bootstrap';

import homecompetition from './images/arml-1.jpg';

import hmmt_2024 from './images/2024-hmmt.jpg';
import arml_2024 from './images/2024-arml.jpg';

import Sections from './helpers/sections';
import AlternatingImages from './helpers/alternatingimages';
import LeaderSection from './helpers/LeaderSection';
import desc from './helpers/descriptions';
import PageTemplate from './helpers/pagetemp';

function Myhome() {

  var about_us_sections = [
    <>
      <div className="spacious-text">{desc.AboutUs}
      </div>
    </>
  ]

  var why_join_sections = [
    <>
      <div className="spacious-text">{desc.WhyJoin}
      </div>
    </>
  ]

  function Clickable({ href, children }) {
    return (
      <span className="clickable" onClick={() => window.location.href = href}>
        {children}
      </span>
    );
  }

  var competition_sections = AlternatingImages({
    sections: [
      {
        "title": <Clickable href="/arml">ARML</Clickable>,
        "description": <p>{desc.ARML}</p>,
        "image": arml_2024
      },
      {
        "title": <Clickable href="/hmmt">HMMT</Clickable>,
        "description": <p>{desc.HMMT}</p>,
        "image": hmmt_2024
      },
      {
        "title": <Clickable href="/other">Other</Clickable>,
        "description": <p>Over the past years, we have participated in several other competitions as well, including the Online Monmouth Math Competition and the ARML Local competition. </p>,
        "image": homecompetition
      }
    ],
    key: 'competition-desc'
  });

  var sections = [
    {
      "title": "About Us",
      "items": about_us_sections,
    },
    {
      "title": "Why Join?",
      "items": why_join_sections,
    },
    {
      "title": "Competitions",
      "items": competition_sections
    },
    {
      "title": "Meet our Leaders",
      "items": [<LeaderSection />]
    }, 
    {
      "title": "Join Our Discord!", 
      "items": [<Discord />]
    }
  ];

  return <>
    <div id='home-page'>
      <PageTemplate id="home-page-body">
        <Sections sections={sections} />
        <div style={{ height: '60px' }}></div>
      </PageTemplate>
    </div>
  </>
}

function Discord() {
  return <Row>
      <Col md={6} className="discord-col">
        <div className="discord-wrapper">
          <iframe className="discord-iframe"
            src="https://discord.com/widget?id=955691009981960192&theme=dark"
            width="350"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
      </Col>
      <Col md={6}>
        <div className="discord-blurb">
          <p className="spacious-text">
            By joining our Discord, you can stay up-to-date with all of our events, meetings, and announcements. You can also ask questions, get help with problems, and chat with other members of the club. We have channels for all sorts of topics, including math, competitions, and general discussion. We hope to see you there!
          </p>
        </div>
      </Col>
    </Row>
}

function Mylanding() {
  return <div id="landing" key="home-landing">
    <Container className="landing-container">
      <div className="landing-logo-wrapper">
        <div className="landing-logo-container">
          <Image
            src="logos/logo1000.jpg"
            alt="Oregon Math Circle"
            className="landing-logo-image"
          />
        </div>
      </div>

      <div>
        <Stack gap={1} className="mb-5 px-3">
          <h1 className="text-center landing-welcome-title">Oregon Math Circle</h1>
          <p className="text-center landing-description">An Oregon-wide club for high school students interested in math.</p>
        </Stack>
      </div>
    </Container>
  </div>
}

export { Myhome, Mylanding };