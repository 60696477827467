import React from 'react';
import { Button, Stack } from 'react-bootstrap';

// headerOnClick scrolls to the section title 
function Sections({ sections, title_block: TitleBlock = ({ children }) => <h1 className="my-5">{children}</h1>, headers = false, headerOnClick=(()=>{})}) {
  if (headers) {
    sections.splice(0, 0, {
      title: "",
      items: [(
        <div className="header-row-wrapper">
          <div className="header-row">
            {sections.map((section, sectionIndex) => (
              <Button
                className="header-button"
                key={`header-button-${sectionIndex}`}
                href={`#section-${sectionIndex}`}
                onClick={() => headerOnClick(sectionIndex)}
              >
                {section.title}
              </Button>
            ))}
          </div>
        </div>
      )]
    });
  }

  return <div className="my-sections">
    {sections.map((section, i) => (
      <div key={`${section.title}-section-${i}`} id={`section-${i}`} className={`page-section ${section?.enhance ? 'enhanced' : ''}`}>
        {section.title && <div className="section-title"><TitleBlock>{section.title}</TitleBlock></div>}
        <Stack gap={3}>
          {section.items.map((item, j) => (
            <div key={`section-${i}-item-${j}`}>
              {item}
            </div>
          ))}
        </Stack>
      </div>
    ))}
  </div>;
}

export default Sections;
