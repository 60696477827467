import React from 'react';
import PageTemplate from '../helpers/pagetemp';
import Sections from '../helpers/sections';

function MyOther() {

    const competitions = [
        {
            title: "Online Monmouth Math Competition",
            about: "The Online Monmouth Math Competition (OMMC) is a relatively new online math competition with very high-quality problems meant for teams of 4. In recent years, it has had over 400 teams from around the world. The contest is held in the Spring and has two rounds — a 25-question computational qualifier round and a 10-question proof-based final round. A near-perfect score is generally required to qualify for the final round.",
            pastResults: <span>In 2023, our top team qualified for the final round with a score of <strong>23/25</strong> on the qualifier and placed <strong>7th</strong> in the final round.</span>,
            recentResults: [["Qualifier Score", "24/25", []], ["Finals Rank", 13, ["number-style"]], ["Number of Teams", "400+", []]]
        },
        {
            title: "ARML Local Contest",
            about: "The ARML Local Contest is similar to the bigger ARML competition but is taken locally across the nation at various sites rather than at the four main sites. The contest is given to smaller teams compared to ARML (usually around 6 students) and is generally easier than ARML.",
            pastResults: <span>In 2023, our team placed <strong>13th</strong>, and our top individual student tied for <strong>6th</strong> place.</span>,
            recentResults: [["Number of Teams", "300+", []], ["Number of Students", "2000+", []], ["Team Rank", 13, ["number-style"]], ["Top Student Rank", 22, ["number-style"]]]
        },
        {
            title: "Mathdash Fall League",
            about: "The Mathdash Fall League is a very new competition where teams of 4 compete in various weekly contests individually and as a team. It will be held in the fall of 2024.",
            pastResults: <span>In progress.</span>,
            recentResults: {}
        }
    ];

    var sections = competitions.map((comp, index) => (
        {
            title: comp.title,
            "items": [
                <div>
                    <p>{comp.about}</p>

                    {comp.pastResults && (
                        <div>
                            <h5>Past Results</h5>
                            <p>{comp.pastResults}</p>
                        </div>
                    )}

                    {comp.recentResults && Object.keys(comp.recentResults).length > 0 && (
                        <div>
                            <h5>2024 Results</h5>
                            <div className="row justify-content-center">
                                {comp.recentResults.map((result, index) => (
                                    <div key={index} className="col-12 col-sm-3 text-center my-3">
                                        <div className="circle-style">
                                            <div className={result[2].join(' ')}>
                                                {result[1]}
                                                {result[2].includes('number-style') && <sup style={{
                                                    color: 'white'
                                                }}>{getOrdinalSuffix(result[1])}</sup>}
                                            </div>

                                        </div>
                                        <span className="mt-2"><strong>{result[0]}</strong></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ], 
            enhance: true
        }));


    return (
        <PageTemplate id="other-comp-page">
            <h1 className="my-5">Other Competitions</h1>
            <div className="spacious-text">
                Our club has participated in many other competitions over the years. Some of our top results are listed below.
            </div>

            <Sections sections={sections} title_block={({ children }) => <h3 style={{
                textAlign: 'center',
            }} className="mt-2">{children}</h3>} />
        </PageTemplate>
    );
}

// Helper functions and styles
function getOrdinalSuffix(i) {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return 'st';
    }
    if (j === 2 && k !== 12) {
        return 'nd';
    }
    if (j === 3 && k !== 13) {
        return 'rd';
    }
    return 'th';
}

export default MyOther;
