import React from 'react';
import { Accordion } from 'react-bootstrap';

function AccordianSet({ sections, activeKey, setActiveKey }) {
    const renderSectionItems = (secs, sectionIndex, key) => (
        <Accordion className="my-accordian" activeKey={activeKey} onSelect={setActiveKey} key={`accordian-${key}`}>
            {secs.map((faq, index) => (
                <Accordion.Item eventKey={`${sectionIndex}-${index}`} key={`${secs[0].title}-${index}`}>
                    <Accordion.Header>{faq.title}</Accordion.Header>
                    <Accordion.Body>{faq.body}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );


    sections = sections.map((section, sectionIndex) => ({
        title: <div id={`section.title`}>{section.title}</div>,
        items: [renderSectionItems(section.items, sectionIndex, section.title)],
    }));

    return sections;
}

export default AccordianSet;