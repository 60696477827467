import { Col, Image, Row } from 'react-bootstrap';

export default function AlternatingImages({ sections, key }) {
    return sections.map((section, i) => {
        var col_1 = (
            <Col className="alt-img-info">
                {section.title && <h3>{section.title}</h3>}
                <div className="spacious-text">{section.description}</div>
            </Col>
        ); // COMPETITION TITLE & DESCRIPTION

        var col_2 = (<Col className="alt-img-image"><Image src={section.image} alt={section.title} style={{ width: '100%' }} /> </Col>); // COMPETITION IMAGE

        return (
            <div>
                <Row key={`${key}-alt-img-row-${i}`} xs={1} md={2}>
                    {i % 2 === 0 ? col_1 : col_2}
                    {i % 2 === 0 ? col_2 : col_1}
                </Row>
            </div>
        )
    });
}