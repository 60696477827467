import { useState } from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import Sections from './helpers/sections';
import PageTemplate from './helpers/pagetemp';
import faq_sections from '../update/faq';
import AccordianSet from './helpers/accordian_set';

function MyFAQ() {
  const [activeKey, setActiveKey] = useState(null);

  var sections = AccordianSet({ sections: faq_sections, activeKey: activeKey, setActiveKey: setActiveKey });

  return (
    <PageTemplate id="faqpage">
      <h1 className="mt-5 mb-4">FAQ</h1>
      <Sections
        sections={sections}
        title_block={({ children }) => (
          <h4 style={{ textAlign: 'center', marginBottom: '2rem' }}>{children}</h4>
        )}
        headers={true}
        headerOnClick={(sectionIndex) => setActiveKey(`${sectionIndex}-0`)}
      />
    </PageTemplate>
  );
}

export default MyFAQ;
