var desc = {
    "AboutUs": "We are an Oregon-wide math circle for high school students interested in math. We run weekly meetings, problem-of-the-days (computational and proof), annual contests, and also have an active and engaging server. While our primary focus is on competition math, we also have a strong emphasis on non-competition topics and encourage students to explore all areas of mathematics. We are a student-run organization, and our leaders are dedicated to providing a fun and engaging environment for all students.", 

    "WhyJoin": "By joining, you will have access to a community of like-minded individuals who share your passion for mathematics. Students benefit by gaining mentorship from older students, learning new topics, and improving their problem-solving skills through practice meetings and POTDs. Students also have the opporunity to attend our weekly classes on advanced topics such as Group Theory and Game Theory. To join, scroll down to the bottom of the page, join our Discord, and fill out the form in the #info channel.",

    "ARML": "The American Regions Mathematics League (ARML) is an annual, national high school mathematics team competition held simultaneously at four locations in the United States near the start of June. The competition consists of several events, including a team round, a power round, an individual round, and a relay round, with both individual and team rankings across two divisions.", 

    "HMMT": "The Harvard-MIT Mathematics Tournament (HMMT) is a high school math competition held annually in November and February, with both competitions being independent. The tournament is run by students from Harvard University and the Massachusetts Institute of Technology. The competition consists of several rounds, and is sandwiched by a series of lectures and events."
}

export default desc;