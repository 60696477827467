import PageTemplate from "./helpers/pagetemp";
import Sections from "./helpers/sections";

import LeaderSection from "./helpers/LeaderSection";

import { Row, Col, Image } from "react-bootstrap";

var members = ['Aashi Dixit', 'Adley Jackson', 'Advait Ajay', 'Alan Kappler', 'Alan Zhong', 'Ananth Namboothiry', 'Anay Aggarwal', 'Andrew Ma', 'Aneesh Malyavanatham', 'Anika Malyavanatham', 'Anirudh Jagannathan', 'Anirudh Thodupunuri', 'Anshul Mantri', 'Anushi Mittal', 'Anya Li', 'Ariana Bajaj', 'Arin Jander', 'Arjun Agarwal', 'Arush Goswami', 'Arushi Mantri', 'Ashvant Daniel', 'Atharv Kapoor', 'Audrey Hou', 'David Kong', 'Devin Chen', 'Ekansh Mittal', 'Ethan Y. Zhang', 'Evan Luo', 'Garud Shah', 'Havish Sripada', 'Isha Marla', 'Jeffrey Fang', 'Jin Yamashita', 'John Kong', 'Jonathan', 'Joseph Liang', 'Justin Xia', 'Kai Yamashita', 'Keshav Karumbunathan', 'Kevin Du', 'King Hey Chan', 'Kushal Rao', 'Leonard Yan', 'Luna González González', 'Manu Isaacs', 'Muen Teng', 'Nathan Ye', 'Neil Natarajan', 'Nirvaan Gupta', 'Nividh Singh', 'Pragya Birla', 'Prisha Garg', 'Ram Goel', 'Rishan Chakraborty', 'Robin Sinha', 'Roushil Satta', 'Ryan Lu', 'Ryan Zhang', 'Sean Ji', 'Shreyan Paliwal', 'Shunyu Du', 'Solomon Methvin', 'Sophia Han', 'Suyash Pandit', 'Theodore Wang', 'Thomas Wang', 'Victor Ene', 'Vihaan Paliwal', 'Vihaan Reddy Satti', 'William Guo', 'Wing Hey Chan', 'Winston Chan', 'Xerxes Wadia', 'Zayeed Saffat'];

// remove duplicates from members
members = members.filter((item, index) => members.indexOf(item) === index);

// sort members alphabetically
members.sort();

function MyMembers() {
    var sections = [
        {
            "title": "",
            "items": [<LeaderSection />]
        }, 
        {
            "title": "Members", 
            "items": [<MemberList members={members} />]  
        }
    ];

    return (
        <PageTemplate id="members-page">
            <h1 className="my-5">Staff</h1>
            <Sections
                sections={sections}
                title_block={({ children }) => (
                    <h4 className="my-4" style={{ textAlign: 'center' }}>{children}</h4>
                )}
            />
        </PageTemplate>
    );
}

function MemberList({ members }) {
    return (
        <Row className="member-list">
            {members.map((member, index) => (
                <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    className="member-item"
                >
                    <span className="delimiter">★</span> {member}
                </Col>
            ))}
        </Row>
    );
}




export default MyMembers;