import { Container } from "react-bootstrap";

function PageTemplate({ id, children }) {
    return (
        <div id={id} className="mb-5 page-container">
            <Container>
                {children}
            </Container>
        </div>
    )
}

export default PageTemplate;